import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import MegaMenuBlockLink from "@insightfulscience/atomic-react/MegaMenu/MegaMenuBlockLink";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import { useLocale } from "@insightfulscience/i18";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import Icon from "@insightfulscience/atomic-react/Icon";
import ButtonTheme from "@insightfulscience/atomic-react/ButtonTheme";
import routes from "../routes";

const ProductsTopBar = () => {
	const [t] = useLocale("header");

	return (
		<MegaMenu spacing={{ py: 1 }}>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.prism} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Prism.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.PRISM")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.geneious} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Geneious.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.GENEIOUS")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href="#" onClick={e => e.preventDefault()}>
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-SnapGene.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.SNAPGENE")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.labArchives} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-LabArchives.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.LABARCHIVES")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.proteinMetrics} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-Protein-Metrics.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.PROTEINMETRIC")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.omiq} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-OMIQ.svg" />
					<MegaMenuBlockLink.Description>{t("topbar.products.OMIQ")}</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.fcsExpress} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-FCS-Express.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.FCSEXPRESS")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>
			<MegaMenuCol lg={3} md={4}>
				<MegaMenuBlockLink href={routes.external.products.nQuery} target="_blank">
					<MegaMenuBlockLink.Icon />
					<MegaMenuBlockLink.Image src="/images/dotmatics/lab-archives/top-bar/PreNav-Logo-nQuery.svg" />
					<MegaMenuBlockLink.Description>
						{t("topbar.products.NQUERY")}
					</MegaMenuBlockLink.Description>
				</MegaMenuBlockLink>
			</MegaMenuCol>

			<MegaMenuCol sm={12} md={12} lg={12}>
				<MegaMenuItem>
					<ButtonTheme.Link
						href={routes.external.portfolio}
						size="xs"
						spacing={{ px: 5, py: 3, mt: 4, mb: 8 }}
						styleType="topbar"
						target="_blank"
					>
						{t("topbar.products.BTN")}
						<Icon spacing={{ ml: 4 }} name="link-ext" />
					</ButtonTheme.Link>
				</MegaMenuItem>
			</MegaMenuCol>
		</MegaMenu>
	);
};

export default ProductsTopBar;
